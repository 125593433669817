import React, { Component } from 'react';
import $ from 'jquery';
import {
	formatPhoneText,
	formatDate,
	formatZipCode,
	formatDateAPI,
	isMobile,
	scrollToTop,
	regExValidation,
	grabUTMparams,
	grabResponseParam,
	isObjEmpty,
	replaceAllHelper,
	generateUniqueID
} from '../../helpers';
import Button from '../atoms/button';
import Heading from '../molecules/heading';
import QuestionnaireStep from '../molecules/questionnaire-step';
import Loader from '../molecules/loader';

class Questionnaire extends Component {
	constructor(props) {
		super(props);
		this.state = {
			onSubmitMessage: this.props.onSubmitMessage,
			thankYouURL: this.props.thankYouURL,
			disclaimer: this.props.disclaimer,
			steps: this.props.steps,
			verifyInformation: this.props.verifyInformation,
			totalSteps: this.props.steps.length,
			isFacebook: this.props.facebook,
			isYahoo: this.props.yahoo,
			isGoogle: this.props.google,
			isThankYouChatBot: this.props.thankYouChatBot,
			currentStep: 1,
			currentStepName: '',
			currentStepButtonID: '',
			nextStep: 2,
			lastStep: false,
			showNextBtn: this.props.steps[0].showButton,
			showDisclaimer: false,
			showTrustLoader: false,
			showSubmit: false,
			showThankYouMessage: false,
			showThankYouPage: false,
			showVerifyInformation: false,
			delay: false,
			isLoading: true,
			loaderProgress: 10,
			showLinearLoader: false,
			showCircularLoader: false,
			showOnLoadLoader: true,
			sliderValue: 0,
			rules: [],
			verifyInformationResponse: {},
			response: {},
			internalLeadID: '',
			secondInternalLeadID: '',
			submitADRData: {},
			submitLeadData: {},
			postURL: '/api/submit',
			postURLFacebook: '/api/submit-Facebook',
			postURLYahoo: '/api/submit-Yahoo',
			postURLGoogle: '/api/submit-Google',
			postCallNumber: 0,
			tracking: {},
			trackingURLs: {},
			isTesting: (process.env.NODE_ENV === 'development'),
			useTestData: false,
			testingData: {
				tracking: {"utm_source":"taboola","utm_medium":"display","utm_campaign":"8702417","utm_content":"2971640945","utm_term":"msn-msn", "utm_search_term":"SSO12345678sdfs455"},
				response: {
					behind_on_payments: "gt_60days",
					city: "Miami",
					date_of_birth: "08-01-1990",
					day_phone: "954-305-1234",
					email: "priscilla@mailinator.com",
					first_name: "Priscilla",
					// last_name: "Pi",
					state: "FL",
					street: "248 NE 14 St",
					unsecured_debt: "50000",
					zip: "33132",
				}
			}

		};

		this.readUrlParams = this.readUrlParams.bind(this);
		this.setStateForNextStep = this.setStateForNextStep.bind(this);
		// this.createStepDisclaimer = this.createStepDisclaimer.bind(this);
		this.handleVerifyInformationSubmit = this.handleVerifyInformationSubmit.bind(this);
		this.handleStepChange = this.handleStepChange.bind(this);
		this.handleLoaderComplete = this.handleLoaderComplete.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.createDataForAPI = this.createDataForAPI.bind(this);
		this.submit = this.submit.bind(this);
	}

	componentDidMount() {
		this.onLoadLoader();
		this.getTrackingURLs();
		this.setupData();
	}

	onLoadLoader() {
		setTimeout(() => {
			this.setState({
				isLoading: !this.state.isLoading,
				showOnLoadLoader: !this.state.showOnLoadLoader,
			});
		}, 500);
	}

	setupData() {
		if(this.state.isTesting && this.state.useTestData) {
			this.setState({
				tracking: this.state.testingData.tracking,
				response: this.state.testingData.response
			});
		}
		this.readUrlParams();
	}
	
	readUrlParams() {
		// let testReferrerURL = "https://learn.weeklyfinancialsolutions.com/open-program/?utm_source=taboola_sso&utm_medium=display&utm_campaign=9642231&utm_term=omgstudios-tradingblvd&utm_content=2986641628&utm_publisher=1134458&tblci=GiDNYQKNDw5IQttGzHi4gGKZqMiahkuPkhUtsXz3pj7KwiCtuD0omsDG57uz_vzPAQ";
		// let utmParamsObj = grabUTMparams(testReferrerURL);

		let utmParamsObj = grabUTMparams(this.state.trackingURLs.referrer, this.state.isFacebook, this.state.isThankYouChatBot, this.state.isYahoo, this.state.isGoogle);
		let responseParamObj = grabResponseParam('resp');

		if(!isObjEmpty(utmParamsObj)) {
			this.setState({
				tracking: utmParamsObj,
			});
		}

		if(!isObjEmpty(responseParamObj)) {
			Object.keys(responseParamObj).forEach((key, index) => {			
				let value = responseParamObj[key];
				let response = {
					key: key,
					val: value,
				};
				this.setStateForNextStep(response);
			})
		}
	}
	
	getTrackingURLs() {
		this.setState({
			trackingURLs: {
				referrer: document.referrer,
				form: document.URL
			}
		});
	}

	submit() {
		return new Promise((resolve, reject) => {
			let data = {
				submitADRData: this.state.submitADRData,
				submitLeadData: this.state.submitLeadData,
				trackingURLs: this.state.trackingURLs,
				postCallNumber: this.state.postCallNumber,
				internalLeadID: this.state.internalLeadID,
				secondInternalLeadID: this.state.secondInternalLeadID,
				isFacebook: this.state.isFacebook,
				isYahoo: this.state.isYahoo,
				isGoogle: this.state.isGoogle,
			};
			let url = (this.state.isFacebook) ? this.state.postURLFacebook : (this.state.isYahoo) ? this.state.postURLYahoo : (this.state.isGoogle) ? this.state.postURLGoogle : this.state.postURL;
			// console.log('JSON.stringify(data): ', JSON.stringify(data));

			$.ajax({
				type: 'POST',
				url: url,
				data: JSON.stringify(data),
				crossDomain: true,
				success: (response, status, xhr) => {
					console.log('success');
					let responseParsed = JSON.parse(response);
					// console.log('responseParsed: ', responseParsed);
					setTimeout(() => resolve(responseParsed), 2000);
				},
				error: (xhr, ajaxOptions, thrownError) => {
					console.log('error - ', xhr.responseText);
					// let responseParsed = JSON.parse(xhr.responseText);
					// console.log('responseParsed: ', responseParsed);
					// setTimeout(() => resolve(responseParsed), 2000);
					setTimeout(() => resolve({value:false, verifyInformation:true}), 2000);
				},
			});
		});
	}

	createDataForAPI(resolve, reject) {		
		Object.keys(this.state.response).forEach((key, index) => {
			let value = this.state.response[key];
			let newValue = value.trim();			
			
			if (key === 'date_of_birth') {
				// API accepts date format: YYYY-MM-DD
				newValue = formatDateAPI(value);
			}
			if (key === 'HomePhone') {				
				// API accepts phone number format: nnnnnnnnnn
				newValue = replaceAllHelper(value, '-', '');
			}
			if (key === 'TotalDebt') {
				newValue = parseInt(value);
			}

			if (key === 'state' || key === 'zip' || key === 'street' || key === 'street2' || key === 'city') {
				if(key === 'state') {
					this.setState((prevState) => ({
						submitADRData: {
							...prevState.submitADRData,
							'State': newValue,
						},
						submitLeadData: {
							...prevState.submitLeadData,
							'State': newValue,
						}
					}));
				} else {
					this.setState((prevState) => ({
						submitLeadData: {
							...prevState.submitLeadData,
							[key]: newValue,
						}
					}));
				}
			} else {
				this.setState((prevState) => ({
					submitADRData: {
						...prevState.submitADRData,
						[key]: newValue,
					},
					submitLeadData: {
						...prevState.submitLeadData,
						[key]: newValue,
					}
				}));
			}

			if(key === 'FirstName' || key === 'LastName' || key === 'HomePhone' || key === 'Email') {
				this.setState((prevState) => ({
					verifyInformationResponse: {
						...prevState.verifyInformationResponse,
						[key]: newValue,
					},
				}));
			}
		});

		let prefix = (this.state.isFacebook) ? 'SSO-FB-' : (this.state.isYahoo) ? 'SSO-YH-' : (this.state.isGoogle) ? 'SSO-GG-' : 'SSO';
		let internalLeadID = (this.state.postCallNumber === 0) ? generateUniqueID(prefix) : this.state.internalLeadID;
		let secondInternalLeadID = (this.state.postCallNumber > 0) ? generateUniqueID(prefix) : this.state.secondInternalLeadID;

		this.setState((prevState) => ({
			postCallNumber: this.state.postCallNumber + 1,
			internalLeadID: internalLeadID,
			secondInternalLeadID: secondInternalLeadID,
			submitADRData: {
				...prevState.submitADRData,
				subid1: (this.state.tracking && this.state.tracking.utm_source) ? this.state.tracking.utm_source : '',
				subid2: (this.state.tracking && this.state.tracking.utm_campaign) ? this.state.tracking.utm_campaign : '',
				subid3: (this.state.tracking && this.state.tracking.utm_term) ? this.state.tracking.utm_term : '',
				subid4: (this.state.tracking && this.state.tracking.utm_content) ? this.state.tracking.utm_content : '',
				subid5: (this.state.tracking && this.state.tracking.utm_publisher) ? this.state.tracking.utm_publisher : ''
			}
		}));

		resolve();
	}

	async handleSubmit() {
		if(this.state.showVerifyInformation) {
			await this.handleVerifyInformationSubmit();
		}

		this.setState({
			isLoading: !this.state.isLoading,
			showCircularLoader: !this.state.showCircularLoader,
		});

		const submitDataPromise = new Promise(this.createDataForAPI);
		submitDataPromise.then(this.submit).then((result) => {		
			// let chatResponse = '';
			// let length = Object.keys(this.state.verifyInformationResponse).length;
			// let pos = 0;

			// $.each(this.state.verifyInformationResponse, (key, value) => {
			// 	pos++;
			// 	let connectingCharacter = (pos === length) ? '' : '&';
			// 	chatResponse = chatResponse + key + '=' + value + connectingCharacter;
			// });

			// let chatResponseEncoded = window.btoa(chatResponse);
			// let chat = '&chat=' + chatResponseEncoded;
			let thankYouURL = (this.state.thankYouURL) ? this.state.thankYouURL : 'thank-you';

			// let urlParams = '/' + thankYouURL + '/?SSOleadID=' + this.state.internalLeadID + chat;

			let urlParams = '/' + thankYouURL + '/?SSOleadID=' + this.state.internalLeadID;
			window.location.href = window.location.origin + urlParams;
		});
	}

	validateFormItem() {
		const toggleErrorClasses = (field) => {
			const MUI_ROOT = '.MuiFormControl-root'
			const MUI_ERR_CLASSNAME = 'Mui-error';
			const MUI_ELEMENTS = [
				'.MuiFormLabel-root',
				'.MuiInputBase-root',
				'.MuiFormHelperText-root'
			];

			MUI_ELEMENTS.map(element => field.closest(MUI_ROOT).querySelector(element)?.classList.toggle(MUI_ERR_CLASSNAME));
		}

		let formStep = document.getElementsByClassName('co-questionnaire__step--active');
		let formItems = formStep && formStep.length ? formStep[0].getElementsByClassName('js-required') : false;
		let isRequired = formItems && formItems.length > 0;

		if (!isRequired) return true;

		let isValid = true;
		
		$.each(formItems, (index, formItem) => {
			let formField = formItem.querySelector('input, select');
			let valueType = formField.closest('.MuiFormControl-root').dataset.valueType;
			let value = (formField.value = formField.value.trim());

			if (!regExValidation(valueType, value)) {
				toggleErrorClasses(formField);
				isValid = false;
			} else {
				toggleErrorClasses(formField);
			}
		});

		return isValid;
	}

	async onButtonClick(e, submitClicked) {
		const saveGoogleAddressResponse = (activeStepGoogleAddress) => {
			let fields = activeStepGoogleAddress.querySelectorAll('input, select');

			if(fields.length) {
				$.each(fields, (index, item) => {			
					let key = item.name;
					let value = (item.value = item.value.trim());
					this.saveQuestionnaireResponse(key, value);
				});
			}
		}
		const sliderResponse = (activeStepSlider) => {
			let sliderInputField = $('.co-questionnaire__step--active .ca-slider-field input');
			let sliderNameValue = {
				key: activeStepSlider.data('slider'),
				val: sliderInputField[0].value,
			};
			return sliderNameValue;
		};

		let valid = this.validateFormItem();
		if (!valid) return console.log('error-- value not valid');

		if (isMobile()) scrollToTop();

		let lastStep = e.target.classList.contains('js-submit__last-step');

		let activeStepGoogleAddress = $('.co-questionnaire__step--active .ca-places-autocomplete--google');
		if(activeStepGoogleAddress.length) await saveGoogleAddressResponse(activeStepGoogleAddress[0]);

		if (submitClicked && lastStep) {
			this.handleSubmit();
		} else {
			let activeStepSlider = $('.co-questionnaire__step--active .ca-slider-field');
			let response = activeStepSlider.length ? sliderResponse(activeStepSlider) : false;
			this.setStateForNextStep(response, submitClicked);
		}
	}

	handleVerifyInformationSubmit() {
		let formStep = document.getElementsByClassName('co-questionnaire__step--active');
		let formItems = formStep && formStep.length ? formStep[0].getElementsByClassName('js-required') : false;

		if(formItems) {
			$.each(formItems, (index, formItem) => {			
				let formField = formItem.querySelector('input');
				let key = formField.name;
				let value = (formField.value = formField.value.trim());
				this.saveQuestionnaireResponse(key, value);
			});
		}
	};

	handleStepChange(e, sliderNewValue = false) {
		const handleSelectChange = (e) => {
			this.saveQuestionnaireResponse(e.name, e.value);
		};
		
		const handleChange = (e) => {
			let saveResponse = e.target.closest('.MuiFormControl-root').dataset.save === 'true';

			let formatTextValue = (value, valueType) => {
				switch (valueType) {
					case 'phoneNumber':
						return formatPhoneText(value);
					case 'date':
						return formatDate(value);
					case 'zipCode':
						return formatZipCode(value);
					default:
						return value;
				}
			};
			let key = e.target.name;
			let val = (e.target.value = formatTextValue(e.target.value, e.target.closest('.MuiFormControl-root').dataset.valueType));

			if (saveResponse) {
				this.saveQuestionnaireResponse(key, val);
			}
		};

		const handleOptionChange = (e) => {
			let saveResponse = e.target.dataset.save === 'true';
			let optionNameValue = {
				key: e.target.name,
				val: e.target.value,
			};

			if (this.state.lastStep && saveResponse) {
				this.saveQuestionnaireResponse(optionNameValue.key, optionNameValue.val);
			} else if (!this.state.lastStep) {
				this.setStateForNextStep(saveResponse ? optionNameValue : false);
			}
		};

		const handleSliderChange = (newValue) => {
			this.setState({
				sliderValue: newValue,
			});
		};

		if(e.target) {
			if (e.target.type === 'text' || e.target.type === 'email' || e.target.type === 'select-one') {
				handleChange(e);
			} else if (e.target.type === 'radio') {
				handleOptionChange(e);
			} else if (sliderNewValue !== false) {
				handleSliderChange(sliderNewValue);
			}
		} else if(e.type && e.type === 'select') {
			handleSelectChange(e);
		}
	}

	handleLoaderComplete(isComplete) {
		this.setState({
			isLoading: !isComplete,
			showLinearLoader: !isComplete,
			showCircularLoader: !isComplete,
			showTrustLoader: !isComplete
		});
	}

	setStateForNextStep(response = false, submitClicked = false) {
		const showSubmitBtn = (lastStep) => {
			if (lastStep) return true;

			let formItemNextStep = document.getElementsByClassName('co-questionnaire__step--next');
			return formItemNextStep[0].classList.contains('js-show__submit');
		};

		const showNextBtn = () => {
			const isNextButtonNeeded = (step) => {
				return step.getElementsByClassName('js-show--button').length;
			};

			let formItemNextStep = document.getElementsByClassName('co-questionnaire__step--next');

			let showNextBtn = formItemNextStep && formItemNextStep.length ? isNextButtonNeeded(formItemNextStep[0]) : false;

			return showNextBtn;
		};

		const showDisclaimer = () => {
			const isDisclaimerNeeded = (step) => {
				return step.getElementsByClassName('js-show--disclaimer').length;
			};

			let formItemNextStep = document.getElementsByClassName('co-questionnaire__step--next');

			let showDisclaimer =
				formItemNextStep && formItemNextStep.length ? isDisclaimerNeeded(formItemNextStep[0]) : false;

			return showDisclaimer;
		};

		const showTrustLoader = () => {
			const isTrustLoaderNeeded = (step) => {
				return (step.dataset.loader && step.dataset.loader !== 'false') ? JSON.parse(step.dataset.loader) : false;
			};

			let formItemNextStep = document.getElementsByClassName('co-questionnaire__step--next');

			let showTrustLoader =
				formItemNextStep && formItemNextStep.length ? isTrustLoaderNeeded(formItemNextStep[0]) : false;

			return showTrustLoader;
		};

		const currentStepName = () => {
			if (!showNextBtn()) return '';

			let textField = $('[data-step="' + this.state.nextStep + '"] .ca-text-input-field input');
			let sliderField = $('[data-step="' + this.state.nextStep + '"] .ca-slider-field input');

			if (textField.length) {
				return textField.attr('name');
			} else if (sliderField.length) {
				return sliderField.attr('name');
			}
		};

		const currentStepButtonID = (lastStep) => {
			if (!showNextBtn() && !showSubmitBtn(lastStep)) return '';

			let textField = $('[data-step="' + this.state.nextStep + '"] .ca-text-input-field');
			let sliderField = $('[data-step="' + this.state.nextStep + '"] .ca-slider-field');
			let placesAutocomplete = $('[data-step="' + this.state.nextStep + '"] .ca-places-autocomplete');

			if (placesAutocomplete.length) {
				return placesAutocomplete.data('button-id');
			} else if (textField.length) {
				return textField.data('button-id');
			} else if (sliderField.length) {
				return sliderField.data('button-id');
			}
		};

		let lastStep = this.state.nextStep === this.state.totalSteps;

		this.setState({
			isLoading: !this.state.isLoading,
			showLinearLoader: !this.state.showLinearLoader,
			totalSteps: document.getElementsByClassName('co-questionnaire__step').length,
			currentStep: this.state.nextStep,
			currentStepName: currentStepName(),
			currentStepButtonID: currentStepButtonID(lastStep),
			nextStep: lastStep ? 0 : this.state.nextStep + 1,
			lastStep: lastStep,
			sliderValue: 0,
			showSubmit: showSubmitBtn(lastStep),
			showNextBtn: showNextBtn(),
			showDisclaimer: showDisclaimer(),
			showTrustLoader: showTrustLoader(),
			showThankYouMessage: submitClicked,
		});

		if (response !== false) {
			this.saveQuestionnaireResponse(response.key, response.val);
		}
	}

	saveQuestionnaireResponse = (key, val) => {
		this.setState((prevState) => ({
			response: {
				...prevState.response,
				[key]: val,
			},
		}));
	};

	renderQuestionnaireSteps = () => {
		const checkRules = (title, rules) => {
			let skipStep = false;
			rules.forEach((rule) => {
				if (title === rule) {
					skipStep = true;
				}
			});
			return skipStep;
		};

		const getRules = (e) => {
			const setStateRules = (rules) => {
				this.setState({
					rules,
				});
			};

			let rules = e.target.getAttribute('data-rules');

			if (rules) {
				rules = rules.split(',').concat(this.state.rules);
				setStateRules(rules);
			}
		};

		const createStepDisclaimer = () => {
			console.log('inside createStepDisclaimer');
		};

		const renderQuestionnaireStepClassNames = (step, showSubmit, state) => {
			let modifierClass =
				step === state.currentStep
					? ' co-questionnaire__step--active'
					: step === state.nextStep
					? ' co-questionnaire__step--next'
					: '';
			let showSubmitClass = showSubmit ? ' js-show__submit' : '';
			let classList = 'co-questionnaire__step' + modifierClass + showSubmitClass;
			return classList;
		};

		let stepNum = 1;
		let steps = [];
		let showSubmit = false;

		this.state.steps.map((step, index) => {
			showSubmit = step.showSubmit ? true : false;
			if (!checkRules(step.title, this.state.rules)) {
				steps.push(
					<QuestionnaireStep
						step={stepNum}
						id={`step-${index + 1}`}
						classNames={renderQuestionnaireStepClassNames(stepNum, showSubmit, this.state)}
						key={index}
						title={step.title}
						subTitle={step.subTitle}
						body={step.body}
						sliderValue={this.state.sliderValue}
						disclaimer={step.disclaimer}
						rules={getRules}
						onChange={this.handleStepChange}
						loader={step.loader}
					/>
				);
				stepNum++;
			}
		});

		return steps;
	};

	renderVerifyInformation = () => {
		return (
			<QuestionnaireStep
				classNames='co-questionnaire__step co-questionnaire__step--active js-show__submit'
				title={this.state.verifyInformation.title}
				subTitle={this.state.verifyInformation.subTitle}
				body={this.state.verifyInformation.body}
				disclaimer={this.state.verifyInformation.disclaimer}
				onChange={this.handleStepChange}
				response={this.state.verifyInformationResponse}
			/>
		)
	}

	render() {
		const questionnaireSteps = !this.state.showVerifyInformation && !this.state.isLoading ? this.renderQuestionnaireSteps() : null;

		const verifyInformation = this.state.showVerifyInformation && !this.state.isLoading ? this.renderVerifyInformation() : null;

		const nextButton =
			this.state.showNextBtn &&
			!this.state.showSubmit &&
			!this.state.isLoading ? (
				<div className='l-grid__row l-grid__center-xs co-questionnaire__button'>
					<Button
						type='primary'
						label='Next'
						id={this.state.currentStepButtonID}
						onClick={(e) => {
							this.onButtonClick(e);
						}}
						large
						isForm
					/>
				</div>
			) : null;

		const submitButton = this.state.showSubmit ? (
			<Button
				type='primary'
				label='Submit'
				id={this.state.currentStepButtonID}
				classNames='co-questionnaire__button'
				linkClassNames={this.state.lastStep ? 'js-submit__last-step' : null}
				onClick={(e) => {
					this.onButtonClick(e, true);
				}}
				large
				isForm
			/>
		) : null;

		const disclaimerAboveButton =
			this.state.showSubmit &&
			!this.state.isLoading &&
			this.state.disclaimer.aboveButton ? (
				<div className='co-questionnaire__disclaimer co-questionnaire__disclaimer--above-btn'>
					<p>{this.state.disclaimer.aboveButton}</p>
				</div>
			) : null;

		const disclaimer =
			this.state.showSubmit && !this.state.isLoading ? (
				<div className='co-questionnaire__disclaimer'>
					<p dangerouslySetInnerHTML={{ __html: this.state.disclaimer.message }} />
				</div>
			) : null;

		const phoneDisclaimerBtnText = this.state.lastStep ? 'Submit' : 'Next';
		const disclaimerTelephone =
			this.state.currentStepName === 'HomePhone' && !this.state.isLoading ? (
				<div className='co-questionnaire__disclaimer'>
					<p>By clicking '{phoneDisclaimerBtnText}', you authorize - with respect to the telephone number provided above and any other telephone number associated or related to you that may be provided to us in the future — [--Placeholder-Client-Name-Goes-Here--] and/or any of these <a href='[--URL-GOES-HERE--]' target='_blank'>linked Service</a> Providers to contact you by phone, text, email, mail, or by artificial or pre-recorded voice, even if you have listed yourself on any Do-Not-Call List. You also agree to our Privacy Policy and Terms of Use, and further agree that any person or entity contacting you pursuant to your consent here may use an automatic telephone dialing system, even if the telephone number you provided above (or any telephone number associated with you) is connected to a mobile phone and even if you might be charged for the call. Your consent to receive automated calls or text messages is not required for any purchase or availability of goods/services from [--Placeholder-Client-Name-Goes-Here--] or its partners; if you do not consent, you may call us at [--Placeholder-Client-Phone-Number--] to continue your inquiry.</p>
				</div>
			) : null;

		const thankYouMessage = this.state.showThankYouMessage ? (
			<div className='co-questionnaire__message'>
				<Heading
					title={this.state.onSubmitMessage.title}
					titleType='h2'
					subTitle={this.state.onSubmitMessage.subTitle}
					subTitleType='h3'
				/>
			</div>
		) : null;

		const form =
			!this.state.showThankYouMessage && !this.state.isLoading ? (
				<form id='questionnaire-form' className='l-grid__row l-grid__center-xs'>
					{questionnaireSteps}
					{verifyInformation}
					{disclaimerAboveButton}
					{nextButton}
					{submitButton}
					{disclaimer}
				</form>
			) : null;

		const linearLoader =
			this.state.isLoading && this.state.showLinearLoader && !this.state.showTrustLoader ? (
				<div className='co-questionnaire__loader l-grid__row l-grid__center-xs'>
					<Loader 
						title='Saving your answer...' 
						onComplete={this.handleLoaderComplete} 
						isMui
					/>
				</div>
			) : null;

		const circularLoader =
			this.state.isLoading && this.state.showCircularLoader ? (
				<div className='co-questionnaire__loader l-grid__row l-grid__center-xs'>
					<Loader 
						title='Processing your information...' 
						isCircular 
					/>
				</div>
			) : null;
		
		const onLoadLoader =
			this.state.isLoading && this.state.showOnLoadLoader ? (
				<div className='co-questionnaire__loader l-grid__row l-grid__center-xs'>
					<Loader 
						title='Loading...' 
						isCircular 
					/>
				</div>
			) : null;

		const trustLoader =
			this.state.isLoading && this.state.showTrustLoader ? (
				<div className='co-questionnaire__loader l-grid__row l-grid__center-xs'>
					<Loader 
						trustContent={this.state.showTrustLoader} 
						onComplete={this.handleLoaderComplete} 
						isMui
						isTrust
					/>
				</div>
			) : null;

		return (
			<div className='co-questionnaire l-spacing__p-b--24'>
				<div className='co-questionnaire__wrapper'>
					{thankYouMessage}
					{form}
					{linearLoader}
					{circularLoader}
					{onLoadLoader}
					{trustLoader}
					{disclaimerTelephone}
				</div>
			</div>
		);
	}
}

export default Questionnaire;

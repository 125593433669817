import React from 'react'
import SEO from "../../components/atoms/seo"

import Layout from '../../components/organisms/layout'
import Heading from '../../components/molecules/heading'
import Questionnaire from '../../components/organisms/questionnaire'
import KeyFeatures from '../../components/molecules/key-features'

import steps from "../../data/pages/questionnaire/steps.json"
import verifyInformation from "../../data/pages/questionnaire/verify.json"
import content from "../../data/pages/questionnaire/content.json"

const QuestionnairePage = () => (
  <Layout showBG showFormSecureBanner>
    <SEO title={content.seo.title} />
    <Heading 
        title={content.heading.title}
        subTitle={content.heading.subTitle}
        weight='regular'
    />
    <Questionnaire 
      steps={steps}
      verifyInformation={verifyInformation}
      disclaimer={content.disclaimer}
      onSubmitMessage={content.onSubmit.message}
      thankYouURL={content.onSubmit.thankYouURL}
      thankYouChatBot
    />
    <KeyFeatures
      features={[
        {
            title: 'Key Feature&nbsp;Example Column&nbsp;1',
            image: 'partnership-drk.png',
            imageAlt: ''
        },
        {
            title: 'Key Feature&nbsp;Example Column&nbsp;2',
            image: 'group-drk.png',
            imageAlt: ''
        },
        {
            title: 'Key Feature&nbsp;Example Column&nbsp;3',
            image: 'support-drk.png',
            imageAlt: ''
        }
    ]}
    />
  </Layout>
)

export default QuestionnairePage
